<template>
  <div class="feedbacks-content">
    <a-row class="d-flex align-items-center justify-space-between mb-4" style="margin: -30px; margin-top: -45px; padding: 11px 22px; background: #fff;">
      <a-col :span="24" class="d-flex align-items-center justify-content-between">
        <h3 class="my-2" style="font-size: 20px;">Users</h3>
        <div>
          <a-button style="margin-right:10px;" @click="fetchRequests">
            <a-icon type="reload" />
            Refresh
          </a-button>
          <a-button type="primary" @click="addModal = true">
            <a-icon type="plus" />
            Add
          </a-button>
        </div>
      </a-col>
    </a-row>
    <a-card class="mt-2">
      <div class="d-flex align-items-center justify-content-between mb-4">
        <div class="d-flex beta-users-tabs">
          <div class="beta-users-tab" :class="{'beta-users-tab--active': activeTab == 'users'}" @click="activeTab = 'users'">Users ({{ this.pagination.total }})</div>
            <div class="beta-users-tab" :class="{'beta-users-tab--active': activeTab == 'requests'}" @click="activeTab = 'requests'">Requests ({{ this.paginationRequests.total }})</div>
        </div>
        <div>
          <a-input-search
            v-model="search"
            placeholder="Input search text"
            style="width: 465px;"
            enter-button
          />
        </div>
      </div>
      <a-table
        v-if="activeTab == 'users'"
        :columns="usersColumns"
        :row-key="record => record.id"
        :row-selection="{ selectedRowKeys: selectedUserKeys, onChange: onSelectUserChange }"
        :data-source="betatestsUsersDataC"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
        class="beta-users-table"
      >
        <div slot="email" slot-scope="email, record">
          <span v-if="record.email">
            <span v-if="record.email_decrypted">
              {{ record.decrypt_email }}
              <a-tooltip title="Hide email" placement="bottom">
                <a href="javascript:;">
                  <a-icon type="eye" class="text-primary" @click="encryptValue(record.id)"  />
                </a>
              </a-tooltip>
            </span>
            <span v-else>
              ***@**
              <a-tooltip title="Show email" placement="bottom" v-if="decryptionKey">
                <a href="javascript:;">
                  <a-icon type="eye-invisible" class="text-primary" @click="decryptValue(record.id, record.email)"  />
                </a>
              </a-tooltip>
            </span>
          </span>
          <!-- <a-icon type="eye-invisible" style="color:#1890FF;cursor:pointer;margin-left:8px;" /> -->
        </div>
        <div slot="application" slot-scope="application">
          <a-icon v-if="application?.platform.toLowerCase() === 'android'" type="android" class="text-success" />
          <a-icon v-if="application?.platform.toLowerCase() === 'ios'" type="apple" class="text-primary" />
          <a-icon v-if="application?.platform.toLowerCase() === 'web'" type="global" class="text-default" />
        </div>
        <div slot="actions" slot-scope="adtions, record" class="d-flex align-items-center justify-content-end">
          <!-- <a-popconfirm
            title="Are you sure to change the activity?"
            @confirm="toogleActive(record)"
          >
            <div style="position:relative;">
              <a-switch
                size="small"
                readonly
                v-model="record.is_active"
              />
              <div style="position:absolute;top:0;bottom:0;left:0;right:0;z-index:1;cursor:pointer;"></div>
            </div>
          </a-popconfirm>
          <a-icon type="edit" style="cursor:pointer;color:#1890FF;margin-right:12px;margin-left:12px;" @click.stop="edit(record)" /> -->
          <a-popconfirm
            title="Sure to delete?"
            @confirm.stop="deleteUser(record.login)"
          >
            <a-icon type="delete" style="cursor:pointer;color:#F5222E" />
          </a-popconfirm>
        </div>
      </a-table>
      <div v-if="activeTab == 'users' && selectedUserKeys && selectedUserKeys.length" class="table-selected-counter" style="z-index: 1;">
        <a-tooltip :title="`Unsubscribe ${selectedUserKeys.length} users?`" placement="bottom">
          <a-popconfirm
            v-if="selectedUserKeys.length"
            title="Sure to unsubscribe selected users?"
            @confirm="() => onUnsubscribeMultiple()"
          >
            <a-button type="default" class="text-red"> <a-icon type="delete" /> Delete </a-button>
          </a-popconfirm>
        </a-tooltip>
      </div>
      <a-table
        v-if="activeTab == 'requests'"
        :columns="requestsColumns"
        :row-key="record => record.id"
        :row-selection="{ selectedRowKeys: selectedRequestsKeys, onChange: onSelectRequestChange }"
        :data-source="betatestsRequestsData"
        :pagination="paginationRequests"
        :loading="loading"
        @change="handleTableChange"
        class="beta-users-table"
      >
        <div slot="email" slot-scope="email, record">
          <span v-if="record.email">***@**</span>

          <!-- <a-tooltip title="Show email" placement="bottom" v-if="decryptionKey">
            <a href="javascript:;" v-if="!record.email_decrypted">
              <a-icon type="eye-invisible" class="text-primary" @click="decryptValue(record.id, record.email)"  />
            </a>
          </a-tooltip> -->
          <!-- <a-icon type="eye-invisible" style="color:#1890FF;cursor:pointer;margin-left:8px;" /> -->
        </div>
        <div slot="application" slot-scope="application">
          <a-icon v-if="application?.platform.toLowerCase() === 'android'" type="android" class="text-success" />
          <a-icon v-if="application?.platform.toLowerCase() === 'ios'" type="apple" class="text-primary" />
          <a-icon v-if="application?.platform.toLowerCase() === 'web'" type="global" class="text-default" />
        </div>
        <div slot="actions" slot-scope="adtions, record" style="display:flex;align-items:center;">
          <a-button class="accept-request-btn" style="margin-right:10px;" type="primary" icon="check" ghost @click="acceptRequest(record.id)" />
          <a-popconfirm
            title="Sure to reject?"
            @confirm.stop="rejectRequest(record.id)"
          >
            <a-button type="danger" icon="close" ghost />
          </a-popconfirm>
        </div>
      </a-table>
      <div v-if="activeTab == 'requests' && selectedRequestsKeys && selectedRequestsKeys.length" class="table-selected-counter" style="z-index: 1;">
        <a-tooltip :title="`Accept ${selectedRequestsKeys.length} requests?`" placement="bottom">
          <a-popconfirm
            v-if="selectedRequestsKeys.length"
            title="Sure to accept selected requests?"
            @confirm="() => onAcceptMultiple()"
          >
            <a-button type="default" class="mr-3 text-blue"> <a-icon type="plus" /> Add all </a-button>
          </a-popconfirm>
        </a-tooltip>
        <a-tooltip :title="`Delete ${selectedRequestsKeys.length} requests?`" placement="bottom">
          <a-popconfirm
            v-if="selectedRequestsKeys.length"
            title="Sure to delete selected requests?"
            @confirm="() => onDeleteMultiple()"
          >
            <a-button type="default" class="text-red"> <a-icon type="delete" /> Delete </a-button>
          </a-popconfirm>
        </a-tooltip>
      </div>
    </a-card>
    <a-modal
      v-model="addModal"
      title="Add"
      :footer="null"
      class="splashscreen-modal"
      width="490px"
      :destroyOnClose="true"
    >
      <add-user :betatests="betatests" @update="addUser" @cancel="addModal = false" />
    </a-modal>
    <a-modal
      v-model="editModal"
      title="Edit User"
      :footer="null"
      class="splashscreen-modal"
      width="490px"
      :destroyOnClose="true"
    >
      <edit-user :user="activeUser" :betatests="betatests" @update="addUser" @cancel="editModal = false" @deleteUser="deleteUser($event)" />
    </a-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import apiClient from '@/services/axios'
import accessMix from '@/services/accessmix.js'
import addUser from './addUser.vue'
import editUser from './editUser.vue'

// const testRequestsData = [
//   {
//     id: 1,
//     user_id: 11080047,
//     login: '4440835',
//     email: 'Xh9YOqKzsVQym9TidDSbB7ryJeWCc4Y+BCJhBsnaIJt9vrZFeT8TsnP5Se08WaZ3Tx/9+mZb/9sKaPokaXnte6kwAYAWSFxMPS4lVVdG1f6L5wAS5gNRUoOJIfpwyJ4HPeFN0IZnOiwIB6e+/CXeO9JrG+Y9pyTUob/nKMIkIgg=',
//     application: {
//       application_id: 16,
//       name: 'Olimpcom',
//       bundle_name: 'com.olimp.app',
//       platform: 'android',
//       schema: 'olimpcom://',
//     },
//     app_v: '4.72',
//     created_at: '2023-02-14T12:55:34.000000Z',
//   },
// ]

const usersColumns = [
  {
    title: 'Login',
    dataIndex: 'login',
    key: 'login',
    width: '15%',
    scopedSlots: { customRender: 'login' },
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    width: '15%',
    scopedSlots: { customRender: 'email' },
  },
  {
    title: 'Platform',
    dataIndex: 'application',
    key: 'application',
    width: '15%',
    scopedSlots: { customRender: 'application' },
  },
  {
    title: 'Bundle',
    dataIndex: 'application.name',
    key: 'application.name',
    width: '15%',
  },
  {
    title: 'App version',
    dataIndex: 'app_v',
    key: 'app_v',
    width: '15%',
  },
  {
    title: 'Date',
    dataIndex: 'created_at',
    key: 'created_at',
    width: '15%',
  },
  {
    title: 'Actions',
    width: '10%',
    scopedSlots: { customRender: 'actions' },
  },
]

const requestsColumns = [
  {
    title: 'Login',
    dataIndex: 'login',
    key: 'login',
    width: '15%',
    scopedSlots: { customRender: 'login' },
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    width: '15%',
    scopedSlots: { customRender: 'email' },
  },
  {
    title: 'Platform',
    dataIndex: 'application',
    key: 'application',
    width: '15%',
    scopedSlots: { customRender: 'application' },
  },
  {
    title: 'Bundle',
    dataIndex: 'application.name',
    key: 'application.name',
    width: '15%',
  },
  {
    title: 'App version',
    dataIndex: 'app_v',
    key: 'app_v',
    width: '15%',
  },
  {
    title: 'Date',
    dataIndex: 'created_at',
    key: 'created_at',
    width: '15%',
  },
  {
    title: 'Actions',
    width: '10%',
    scopedSlots: { customRender: 'actions' },
  },
]
export default {
  name: 'index',
  components: {
    addUser,
    editUser,
  },
  mixins: [accessMix],
  computed: {
    ...mapState(['user']),
    betatestsUsersDataC() {
      return this.betatestsUsersData
    },
  },
  data() {
    return {
      activeTab: 'users',
      betatests: [],
      addModal: false,
      editModal: false,
      activeUser: null,
      selectedRequestsKeys: [],
      selectedUserKeys: [],
      betatestsUsersData: [],
      betatestsRequestsData: [],
      pagination: { pageSize: 25 },
      paginationRequests: { pageSize: 25 },
      tableFilters: {},
      tableSorter: {},
      tableDateFilter: null,
      loading: false,
      usersColumns,
      requestsColumns,
      searchField: 'login',
      searchFields: ['login', 'name', 'body'],
      searchValue: '',
      applications: [],
      search: null,
      filters: {
        date: null,
        app: {
          value: 'All',
        },
        type: {
          value: 'all',
          list: [
            {
              value: 'positive',
              label: 'Positive',
            },
            {
              value: 'negative',
              label: 'Negative',
            },
            {
              value: 'all',
              label: 'All',
            },
          ],
        },
      },
      respondForm: {
        email: '',
        comment: '',
      },
      respondFormRules: {
        email: [{ required: true, message: 'Please input email', trigger: 'change' }],
        comment: [{ required: true, message: 'Please input comment', trigger: 'change' }],
      },
      decryptionKey: !!sessionStorage.getItem('app.encryption.privatekey'),
    }
  },
  mounted () {
    this.fetchUsers()
    this.fetchRequests()
  },
  created() {
    // this.makeApplicationFilter()
    this.getBetatests()
    this.getApplications()
  },
  watch: {
    search: {
      handler(value) {
        if (value.length) {
          this.fetchUsers({ login: value })
        } else {
          this.fetchUsers()
        }
      },
    },
  },
  methods: {
    onAcceptMultiple() {
      const url = '/admin/beta/requests/accept'
      return apiClient.post(url, this.selectedRequestsKeys).then((response) => {
        this.$notification.success({
          message: 'Requests accepted',
          description: '',
        })
        this.handleTableChange(this.pagination, this.tableFilters, this.tableSorter)
        this.fetchUsers()
      }).catch(error => { console.log(error) })
    },
    onDeleteMultiple() {
      const url = '/admin/beta/requests/delete'
      return apiClient.delete(url, { params: this.selectedRequestsKeys }).then((response) => {
        this.$notification.success({
          message: 'Requests deleted',
          description: '',
        })
        this.handleTableChange(this.pagination, this.tableFilters, this.tableSorter)
        this.fetchUsers()
      }).catch(error => { console.log(error) })
    },
    onUnsubscribeMultiple() {
      const url = '/admin/beta/users/mass_unsubscribe'
      return apiClient.post(url, this.selectedUserKeys).then((response) => {
        this.$notification.success({
          message: 'Users unsubscribed',
          description: '',
        })
        this.handleTableChange(this.pagination, this.tableFilters, this.tableSorter)
      }).catch(error => { console.log(error) })
    },
    onSelectUserChange(selectedUserKeys) {
      this.selectedUserKeys = selectedUserKeys
    },
    onSelectRequestChange(selectedRequestsKeys) {
      this.selectedRequestsKeys = selectedRequestsKeys
    },
    async getBetatests() {
      const url = '/admin/beta/v1'
      return apiClient.get(url).then((response) => {
        this.betatests = response.data.data
      }).catch(error => { console.log(error) })
    },
    addUser() {
      this.handleTableChange(this.pagination, this.tableFilters, this.tableSorter)
    },
    toogleActive(record) {
      console.log(record)
      // const url = `/admin/beta/v1/${record.id}/status`
      // apiClient.patch(url, { is_active: !record.is_active }).then((response) => {
      //   this.$notification.success({
      //     message: 'Status changed success',
      //     description: '',
      //   })
      //   record.is_active = !record.is_active
      // }).catch(error => {
      //   console.log(error)
      //   this.$notification.error({
      //     message: 'Error while changing status',
      //     description: error.message,
      //   })
      // })
    },
    deleteUser(login) {
      const url = '/admin/beta/users/unsubscribe'
      apiClient.post(url, { login }).then((response) => {
        console.log(response)
        this.$notification.success({
          message: 'User has been successfully deleted',
          description: '',
        })
        this.handleTableChange(this.pagination, this.tableFilters, this.tableSorter)
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Error while deleting user',
          description: error.message,
        })
      })
    },
    edit(item) {
      this.activeUser = JSON.parse(JSON.stringify(item))
      this.editModal = true
    },
    acceptRequest(id) {
      const url = `/admin/beta/requests/${id}/accept`
      apiClient.post(url).then((response) => {
        console.log(response)
        if (response) {
          this.fetchRequests()
          this.fetchUsers()
        } else {
          this.$notification.error({
            message: 'Error while accepting request',
            description: '',
          })
        }
      }).catch(error => {
        console.error(error)
      })
    },
    rejectRequest(id) {
      const url = `/admin/beta/requests/${id}`
      apiClient.delete(url).then((response) => {
        console.log(response)
        if (response) {
          this.fetchRequests()
        } else {
          this.$notification.error({
            message: 'Error while rejecting request',
            description: '',
          })
        }
      }).catch(error => {
        console.error(error)
      })
    },
    handleTableChange(pagination, filters, sorter) {
      console.log('asfdfsafsadsadf', pagination, filters, sorter)
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      this.tableSorter = sorter
      const data = {
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...this.tableFilters,
        searchField: this.searchField,
        searchValue: this.searchValue,
      }
      if (this.tableDateFilter) {
        data.fromDate = this.tableDateFilter.fromDate
        data.toDate = this.tableDateFilter.toDate
      }
      if (this.activeTab === 'users') {
        this.fetchUsers(data)
      } else {
        this.fetchRequests(data)
      }
    },
    fetchUsers(params = {}) {
      this.loading = true
      const reqData = { results: 25, page: this.pagination.current ?? 1, ...params }
      const url = '/admin/beta/users'
      apiClient.get(url, { params: reqData }).then((response) => {
        const pagination = { ...this.pagination }
        pagination.total = response.data.meta.total
        this.loading = false
        response.data.data.forEach(item => {
          item.email_decrypted = false
        })
        this.betatestsUsersData = response.data.data
        this.pagination = pagination
      }).catch(error => {
        console.log(error)
        this.loading = false
      })
    },
    fetchRequests(params = {}) {
      console.log('params:', params)
      this.loading = true
      const reqData = { results: 25, page: this.paginationRequests.current ?? 1, ...params }
      const url = '/admin/beta/requests'
      apiClient.get(url, { params: reqData }).then((response) => {
        const pagination = { ...this.paginationRequests }
        pagination.total = response.data.meta.total
        this.loading = false
        // if (response.data.data.length) {
        this.betatestsRequestsData = response.data.data
        console.log(this.betatestsRequestsData)
        // } else {
        //   this.betatestsRequestsData = testRequestsData
        // }
        this.paginationRequests = pagination
      }).catch(error => {
        console.log(error)
        this.loading = false
      })
    },
    decryptValue(key, value) {
      console.log(key, 'KEY')
      console.log(value, 'VALUE')
      const decKey = sessionStorage.getItem('app.encryption.privatekey')
      if (!decKey) {
        this.$message.error('Decryption key not found in session storage')
        return
      }
      const target = this.betatestsUsersData.filter(item => key === item.id)[0]
      console.log(target)
      const dec = this.$decryptData(decKey, value)
      if (dec) {
        target.email_decrypted = true
        target.email = value
        target.decrypt_email = dec
        console.log(dec, 'DEC')
      } else {
        this.$message.error('Data decryption failed due to invalid key or data content')
      }
    },
    encryptValue(key) {
      const target = this.betatestsUsersData.filter(item => key === item.id)[0]
      target.messenger_login = target.messenger_login_orig
      target.email_decrypted = false
    },
    async getApplications() {
      const url = '/admin/applications'
      return apiClient.get(url).then((response) => {
        this.applications = response.data.data
      }).catch(error => { console.log(error) })
    },
    async makeApplicationFilter() {
      const target = this.feedbacksColumns.filter(item => item.key === 'app')[0]
      await this.getApplications().then((apps) => {
        target.filters = this.applications.map(function(app) {
          return { text: `${app.name} (${app.platform.toUpperCase()})`, value: app.bundle_name }
        })
      })
    },
    toggleSelectedApps(oldApps, app, checked) {
      let newApps = [...oldApps]
      if (checked) {
        newApps.push(app)
      } else {
        newApps = newApps.filter(t => t !== app)
      }
      console.log('new apps', newApps)
      return newApps
    },
  },
}
</script>
<style lang="scss">
.beta-users-table.ant-table-wrapper .ant-table-content {
  color: #000;
  font-size: 14px;
  .ant-table-column-title {
    font-weight: 600;
  }
}
.beta-users-tabs{
  .beta-users-tab {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #D9D9D9;
    min-width: 67px;
    padding: 0 4px;
    cursor: pointer;
    transition: all .2s;
    &--active {
      font-weight: 600;
      border-bottom: 2px solid #1890FF;
      color: #1890FF;
    }
  }
}
.accept-request-btn.ant-btn-primary:hover,
.accept-request-btn.ant-btn-primary:active,
.accept-request-btn.ant-btn-primary:focus {
  background: unset !important;
}
</style>
